@use "../../assets/styles/utilities/colors" as *;
@use "../../assets/styles/utilities/breakpoints" as *;
@use "../../assets/styles/utilities/others" as *;

.status {
  padding: 0.125rem 0.25rem;

  border-radius: 4px;

  font: 700 0.875rem Source Sans Pro, sans-serif;
  text-transform: uppercase;

  &.aguardando-inicio {
    background-color: $color-secondary-lighten-info;

    color: $color-hover-info;
  }

  &.suspenso {
    background-color: $color-secondary-lighten-warning;

    color: $color-hover-warning;
  }

  &.encerrado {
    background-color: $color-secondary-lighten-danger;

    color: $color-hover-danger;
  }

  &.em-andamento {
    background-color: $color-secondary-lighten-success;

    color: $color-hover-success;
  }

  &.selecionado {
    background-color: $color-secondary-lighten-success;

    color: $color-hover-success;
  }

  &.recomendado {
    background-color: $color-secondary-lighten-success;

    color: $color-hover-success;
  }

  &.nao-recomendado {
    background-color: $color-secondary-states-danger-second;

    color: $color-hover-danger;
  }
}
