@use '../../../assets/styles/utilities/colors' as *;
@use '../../../assets/styles/utilities/breakpoints' as *;
@use '../../../assets/styles/utilities/others' as *;

.accordion {
  @include max-width;

  position: relative;
  top: -5rem;

  display: flex;
  align-items: flex-start;
  flex-direction: column;

  gap: 3rem;
}

.accordionCard {
  width: 100%;
  height: 100%;

  padding: 2rem;

  @include card-shadow;

  border-radius: 8px;

  background-color: $color-secondary-white;
}

.accordionTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 3rem;

  > h2 {
    font: 600 2rem Poppins, sans-serif;
    line-height: 1.5;
    color: $color-primary-green;
  }

  > button {
    width: 12rem;
    height: 2.5rem;

    padding: 0.5rem 1rem;

    font: 600 1rem Source Sans Pro, sans-serif;
    color: $color-secondary-white;
    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;

    border: 0;
    border-radius: 4px;

    background-color: $color-primary-green;

    &:disabled {
      opacity: 0.8;

      cursor: not-allowed;
    }
  }
}

.table.insumos {
  width: 100%;
  max-width: 100%;

  .table-head {
    width: 100%;

    .table-row {
      .table-headers {
        font: 700 0.875rem Source Sans Pro, sans-serif;
        text-transform: uppercase;
        text-align: left;

        padding-bottom: 1rem;

        &:last-child {
          width: 1.5rem !important;
        }
      }
    }
  }

  .table-head,
  .table-body {
    .table-row {
      display: grid;
      grid-auto-rows: 1fr;
      grid-template-columns: 1.25fr 1.5fr 0.75fr 1fr 0.5fr;
      grid-template-rows: 1fr;
      gap: 0 0;

      @media (max-width: $sm) {
        grid-template-columns: 1fr 1fr 0.25fr;
      }

      border-bottom: 1px solid $color-secondary-light-gray;

      .table-column {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        align-self: center;
      }
    }
  }
}

.empty {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;

  img {
    max-width: 100%;
    margin-bottom: 1rem;
  }

  h1 {
    font: 600 1.5rem Poppins, sans-serif;
    line-height: 1.5;
    color: $color-primary-green;
  }

  p > a {
    font-weight: 600;
    text-decoration: underline;
    color: $color-primary-dark-green;
  }
}

.sm-display {
  @media (max-width: $sm) {
    display: none;
  }
}

.insumo__contentOuter {
  width: 100%;
}

.insumo__contentInner {
  section {
    margin-top: 1rem;
    padding: 1.5rem;

    border: 1px solid $color-secondary-light-gray;
    border-radius: 4px;
  }
}

// .insumo__trigger {
//   .table-column button svg {
//     transition: transform 300ms;
//   }

//   &.is-open {
//     .table-column button svg {
//       transform: rotateZ(180deg);
//     }
//   }
// }

.icon-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;

  @media (max-width: $sm) {
    width: 100%;

    justify-content: flex-end;
  }

  .icon {
    font-size: 0;

    background: transparent;

    border-radius: 4px;
    border: 1px solid;

    width: 2rem;
    height: 2rem;

    display: flex;
    align-items: center;
    justify-content: center;

    &.edit {
      border-color: #777777;
    }

    &.remove {
      border-color: #ff6b6b;
    }
  }
}
