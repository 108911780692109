$color-primary-green: #00933b;
$color-primary-dark-green: #00652e;
$color-primary-black: #111111;

$color-secondary-dark-gray: #333333;
$color-secondary-gray: #777777;
$color-secondary-light-gray: #dddddd;
$color-secondary-white: #ffffff;

$color-secondary-states-success: #2ed573;
$color-secondary-states-info: #48dbfb;
$color-secondary-states-danger: #ff6b6b;
$color-secondary-states-danger-second: #ecafaf;
$color-secondary-states-warning: #feca57;

$color-secondary-lighten-success: #c3f3d7;
$color-secondary-lighten-info: #def8fe;
$color-secondary-lighten-danger: #ffeaea;
$color-secondary-lighten-warning: #fff2d6;

$color-hover-success: #26bb64;
$color-hover-info: #0abde3;
$color-hover-danger: #ee5253;
$color-hover-warning: #ff9f43;

$color-others-light-green: #cce3d6;
