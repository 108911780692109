@use '../../../../assets/styles/utilities/colors' as *;
@use '../../../../assets/styles/utilities/breakpoints' as *;
@use '../../../../assets/styles/utilities/others' as *;

#editais-aplicados {
  width: 100%;

  position: relative;
  top: -5rem;

  display: flex;
  align-items: flex-start;
  flex-direction: column;

  gap: 3rem;
  padding: 0 6rem;

  @media (max-width: $md) {
    padding: 0 3rem;
  }

  @media (max-width: $sm) {
    padding: 0 1.5rem;
  }
}

div.editais-aplicados-card {
  width: 100%;
  height: 100%;

  padding: 2rem;

  @include card-shadow;

  border-radius: 8px;

  background-color: $color-secondary-white;
}

.table.editais-aplicados {
  width: 100%;
  max-width: 100%;

  .table-head {
    display:flex;
    height:4rem;
    width: 100%;
    margin-left:5px;

    .table-row {
      width:100%;
      display:flex;
      align-items: center;
      .table-headers {
        font: 700 0.875rem Source Sans Pro, sans-serif;
        text-transform: uppercase;
        text-align: left;


        &:last-child {
          width: 1.5rem !important;
        }
      }
    }
  }

  .table-head,
  .table-body {
    width:100%;
    .table-row {
      display: grid;
      grid-auto-rows: 1fr;
      grid-template-columns: 1fr 0.75fr 1fr 0.75fr 0.75fr 0.75fr 0.25fr;
      grid-template-rows: 1fr;
      gap: 0 0;

      @media (max-width: $sm) {
        grid-template-columns: 1fr 1fr 0.25fr;
      }

      border-bottom: 1px solid $color-secondary-light-gray;

      .table-column {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
.table-column {
  margin-left:5px;
}
.editais-aplicados{
  margin-left:14px;
}

.edital-container {
  display:flex;
  flex-direction: row;
}

.checkbox-container {
  padding: 1.5rem 0;
  margin:0 15px 0 -24px;
  width:14px;
}
.checkbox-container-all {
  display:flex;
  height:inherit;
}

.nenhum-edital {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;

  img {
    max-width: 100%;
    margin-bottom: 1rem;
  }

  h1 {
    font: 600 1.5rem Poppins, sans-serif;
    line-height: 1.5;
    color: $color-primary-green;
  }

  p > a {
    font-weight: 600;
    text-decoration: underline;
    color: $color-primary-dark-green;
  }
}

.sm-display {
  @media (max-width: $sm) {
    display: none;
  }
}

.edital__contentOuter {
  width: 100%;
}

.edital__contentInner {
  section {
    margin-top: 1rem;
    padding: 1.5rem;

    border: 1px solid $color-secondary-light-gray;
    border-radius: 4px;
  }
}

.edital__trigger {
  .table-column button svg {
    transition: transform 300ms;
  }

  &.is-open {
    .table-column button svg {
      transform: rotateZ(180deg);
    }
  }
}

.submit-button-container {
  padding:2rem;
  display:flex;
  justify-content: right;
  button {
    border:none;
    border-radius: 4px;
    height:2.5rem;
    width:7rem;
    color: #ffffff;
    padding:0.3rem 1rem;
  }
  .submit-button {
    margin-left:1rem;
    background-color: #00933b;
  }
  .delete-button {
    background-color: #e20909;
  }
}