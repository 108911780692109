@use '../assets/styles/utilities/colors' as *;
@use '../assets/styles/utilities/breakpoints' as *;
@use '../assets/styles/utilities/others' as *;

.opcoes {
  @include max-width;
  min-height: 12rem;

  position: relative;
  top: -5rem;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  gap: 3rem;

  @media (max-width: $md) {
    flex-direction: column;
  }

  @media (max-width: $sm) {
    flex-direction: column;
  }

  .card {
    width: 100%;
    min-height: 12rem;

    padding: 2rem;

    @include card-shadow;

    border-radius: 8px;

    background-color: $color-secondary-white;

    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    > div {
      height: 100%;
      align-self: flex-start;

      > h3 {
        font: 600 1.5rem Poppins, sans-serif;
        line-height: 1.5;
        color: $color-primary-green;

        margin-bottom: 0.5rem;
      }

      > button {
        font: 600 1rem Source Sans Pro, sans-serif;
        color: $color-secondary-dark-gray;

        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 0.25rem;

        border: 0;

        background-color: transparent;
      }
    }

    > img {
      width: 5rem !important;
    }
  }
}
