@use '../../assets/styles/utilities/colors' as *;
@use '../../assets/styles/utilities/breakpoints' as *;
@use '../../assets/styles/utilities/others' as *;

.header {
  width: 100%;
  height: 25rem;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: 2rem;

  @include gradient(background);

  .headerContainer {
    @include max-width;

    .headerContent {
      h1 {
        font: 600 3rem Poppins, sans-serif;
        color: $color-secondary-white;

        margin-bottom: 1rem;

        @media (max-width: $sm) {
          font-size: 2rem;
        }
      }

      p {
        max-width: 32rem;

        font: 400 1rem Source Sans Pro, sans-serif;
        color: $color-others-light-green;
        line-height: 1.5;
      }
    }

    .headerButton{ 

      button{
        display: flex;
        align-items: center;
        justify-content: center;

        gap: 1rem;

        padding: 0.5rem 1rem;
        margin-top: 1rem;

        border: 0;
        border-radius: 4px;

        background-color: $color-secondary-white;

        font: 600 1.125rem Source Sans Pro, sans-serif;
        color: $color-primary-dark-green;
      }
    }  
  }

  
}
