@use '../../../../assets/styles/utilities/colors' as *;
@use '../../../../assets/styles/utilities/breakpoints' as *;
@use '../../../../assets/styles/utilities/others' as *;

#editais-contrato {
  @include max-width;

  position: relative;
  top: -5rem;

  display: flex;
  align-items: flex-start;
  flex-direction: column;

  gap: 3rem;
}

div.editais-contrato-card {
  width: 100%;
  height: 100%;

  padding: 2rem;

  @include card-shadow;

  border-radius: 8px;

  background-color: $color-secondary-white;
}

.table.editais-contrato {
  width: 100%;
  max-width: 100%;

  .table-head {
    width: 100%;

    .table-row {
      .table-headers {
        font: 700 0.875rem Source Sans Pro, sans-serif;
        text-transform: uppercase;
        text-align: left;

        padding-bottom: 1rem;

        &:last-child {
          width: 1.5rem !important;
        }
      }
    }
  }

  .table-head,
  .table-body {
    .table-row {
      display: grid;
      grid-auto-rows: 1fr;
      grid-template-columns: 1.75fr 1.25fr 0.75fr 0.75fr 1fr 0.25fr;
      grid-template-rows: 1fr;
      gap: 0 0;

      @media (max-width: $sm) {
        grid-template-columns: 0.25fr 0.75fr 0.75fr 0.25fr;
      }

      border-bottom: 1px solid $color-secondary-light-gray;

      .table-column {
        margin-left:0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.nenhum-edital {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;

  img {
    max-width: 100%;
    margin-bottom: 1rem;
  }

  h1 {
    font: 600 1.5rem Poppins, sans-serif;
    line-height: 1.5;
    color: $color-primary-green;
  }

  p > a {
    font-weight: 600;
    text-decoration: underline;
    color: $color-primary-dark-green;
  }
}

.sm-display {
  @media (max-width: $sm) {
    display: none;
  }
}

.edital__contentOuter {
  width: 100%;
}

.edital__contentInner {
  section {
    margin-top: 1rem;
    padding: 1.5rem;

    border: 1px solid $color-secondary-light-gray;
    border-radius: 4px;
  }
}

.edital__trigger {
  .table-column button svg {
    transition: transform 300ms;
  }

  &.is-open {
    .table-column button svg {
      transform: rotateZ(180deg);
    }
  }
}