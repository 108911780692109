@use '../../assets/styles/utilities/colors' as *;
@use '../../assets/styles/utilities/breakpoints' as *;
@use '../../assets/styles/utilities/others' as *;

.inicio {
  @include max-width;
  height: 20rem;

  position: relative;
  top: -5rem;

  margin-bottom: 4rem;

  @media (max-width: $md) {
    flex-direction: column;
  }

  @media (max-width: $sm) {
    flex-direction: column;
  }

  .cards {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: flex-start;

    gap: 3rem;

    @media (max-width: $md) {
      flex-direction: column;

      height: 30rem;
    }

    @media (max-width: $sm) {
      flex-direction: column;
    }
  }

  .logos {
    width: 100%;

    @media (max-width: $md) {
      flex-direction: column;
    }

    @media (max-width: $sm) {
      flex-direction: column;
    }
  }

  section + section {
    margin-top: 3rem;
  }
}
