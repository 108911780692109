@use "../../assets/styles/utilities/colors" as *;
@use "../../assets/styles/utilities/breakpoints" as *;
@use "../../assets/styles/utilities/others" as *;

.loader {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
}
