@use '../../assets/styles/utilities/colors' as *;
@use '../../assets/styles/utilities/breakpoints' as *;
@use '../../assets/styles/utilities/others' as *;

.card {
  width: 100%;
  height: 100%;

  padding: 2rem;

  @include card-shadow;

  border-radius: 8px;

  background-color: $color-secondary-white;
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: contain;

  @media (max-width: $md) {
    background-size: 50%;
  }

  @media (max-width: $sm) {
    background-size: contain;
  }

  &.agricultorCard {
    background-image: url('../../assets/images/farmer.svg');
  }

  &.escolaCard {
    background-image: url('../../assets/images/school.svg');
  }

  &.desenvolvedoresCard,
  &.financiadoresCard {
    .logosImages {
      width: 100%;

      background: transparent;

      border-radius: 0;

      box-shadow: none;

      padding: 2rem 0;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      
    }
    &.desenvolvedoresCard{
      .logosImages{
        img {
          max-height: 4rem;
          
          @media (max-width: $md) {
            max-width: 8rem;
            height: 100%;
          }

          @media (max-width: $sm) {
            max-width: 4rem;
            height: 100%;
          }
        }
      }
    }
    &.financiadoresCard{
      .logosImages{
        img {
          max-height: 5rem;
          height: 3rem;

          @media (max-width: 890px) {
              max-width: 6rem;
              height: 100%;
          }
          
          @media (max-width: $md) {
            max-width: 7rem;
            height: 100%;
          }

          @media (max-width: $sm) {
            max-width: 4rem;
            height: 100%;
          }
        }
        .mda{
          img{
            width: 19rem;
            height: auto;
            @media (max-width: 890px) {
              max-width: 13rem;
              height: 100%;
          }
          
          @media (max-width: $md) {
            max-width: 11rem;
            height: 100%;
          }

          @media (max-width: $sm) {
            max-width: 8rem;
            height: 100%;
          }
          }
        }
      }
    }
  }

  h3 {
    font: 600 1.5rem Poppins, sans-serif;
    line-height: 1.5;
    color: $color-primary-green;

    margin-bottom: 0.5rem;
  }

  button {
    font: 600 1rem Source Sans Pro, sans-serif;
    color: $color-secondary-dark-gray;

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 0.25rem;

    border: 0;

    background-color: transparent;
  }
}
