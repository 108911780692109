@use '../../../assets/styles/utilities/colors' as *;
@use '../../../assets/styles/utilities/breakpoints' as *;
@use '../../../assets/styles/utilities/others' as *;

.accordion {
  @include max-width;

  position: relative;
  top: -5rem;

  display: flex;
  align-items: flex-start;
  flex-direction: column;

  gap: 3rem;
}

.accordionCard {
  width: 100%;
  height: 100%;

  padding: 2rem;

  @include card-shadow;

  border-radius: 8px;

  background-color: $color-secondary-white;
}

.accordionTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 3rem;

  > h2 {
    font: 600 2rem Poppins, sans-serif;
    line-height: 1.5;
    color: $color-primary-green;
  }

  > button {
    width: 12rem;
    height: 2.5rem;

    padding: 0.5rem 1rem;

    font: 600 1rem Source Sans Pro, sans-serif;
    color: $color-secondary-white;
    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;

    border: 0;
    border-radius: 4px;

    background-color: $color-primary-green;

    &:disabled {
      opacity: 0.8;

      cursor: not-allowed;
    }
  }
}

.table.producoes {
  width: 100%;
  max-width: 100%;

  .table-head {
    width: 100%;

    .table-row {
      .table-headers {
        font: 700 0.875rem Source Sans Pro, sans-serif;
        text-transform: uppercase;
        text-align: left;

        padding-bottom: 1rem;

        &:last-child {
          width: 1.5rem !important;
        }
      }
    }
  }

  .table-head,
  .table-body {
    .table-row {
      display: grid;
      grid-auto-rows: 1fr;
      grid-template-columns: 2.5fr 2fr 2fr 2fr 0.5fr;
      grid-template-rows: 1fr;
      gap: 0 0;

      @media (max-width: $sm) {
        grid-template-columns: 1fr 1fr 0.25fr;
      }

      border-bottom: 1px solid $color-secondary-light-gray;

      .table-column {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        align-self: center;
      }
    }
  }
}

.empty {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;

  img {
    max-width: 100%;
    margin-bottom: 1rem;
  }

  h1 {
    font: 600 1.5rem Poppins, sans-serif;
    line-height: 1.5;
    color: $color-primary-green;
  }

  p > a {
    font-weight: 600;
    text-decoration: underline;
    color: $color-primary-dark-green;
  }
}

.sm-display {
  @media (max-width: $sm) {
    display: none;
  }
}

.producao__contentOuter {
  width: 100%;
}

.producao__contentInner {
  section {
    margin-top: 1rem;
    padding: 1.5rem;

    border: 1px solid $color-secondary-light-gray;
    border-radius: 4px;
  }
}

.producao__trigger {
  .table-column button svg {
    transition: transform 300ms;
  }

  &.is-open {
    .table-column button svg {
      transform: rotateZ(180deg);
    }
  }
}

.producao-card {
  .producao-card-header {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: $sm) {
        gap: 1rem;
        flex-wrap: wrap-reverse;
      }

      h2 {
        font: 600 1.5rem Poppins, sans-serif;
        line-height: 1.5;
        color: $color-secondary-dark-gray;

        display: flex;
        align-items: center;
        gap: 1rem;
      }

      & > div {
        display: flex;
        gap: 1rem;

        @media (max-width: $sm) {
          width: 100%;

          justify-content: flex-end;

          margin-bottom: 0.5rem;
        }

        .icon {
          font-size: 0;

          background: transparent;

          border-radius: 4px;
          border: 1px solid;

          width: 2rem;
          height: 2rem;

          display: flex;
          align-items: center;
          justify-content: center;

          &.editar-producao {
            border-color: #777777;
          }

          &.remover-producao {
            border-color: #ff6b6b;
          }
        }
      }
    }

    h3 {
      font: 600 1.125rem Source Sans Pro, sans-serif;
      line-height: 1.5;
      color: $color-secondary-dark-gray;
    }

    h4 {
      font: 400 1rem Source Sans Pro, sans-serif;
      line-height: 1.5;
      color: $color-secondary-gray;
    }
  }

  .producao-card-body {
    margin-top: 1.5rem;

    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    div {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      width: 100%;

      padding-bottom: 1rem;

      border-bottom: 1px solid $color-secondary-light-gray;

      &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
      }

      h6 {
        font: 700 0.875rem Source Sans Pro, sans-serif;
        text-transform: uppercase;
      }

      p {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }

      table {
        border: 1px solid $color-secondary-light-gray;
        border-collapse: collapse;

        margin: 0;
        margin-top: 0.5rem;
        padding: 0;

        width: 100%;

        table-layout: fixed;

        @media (max-width: $sm) {
          border: 0;

          thead {
            border: none;

            clip: rect(0 0 0 0);

            height: 1px;
            width: 1px;

            margin: -1px;
            padding: 0;

            overflow: hidden;
            position: absolute;
          }
        }

        tr {
          background-color: $color-secondary-white;

          border: 1px solid $color-secondary-light-gray;

          padding: 0.25rem;

          @media (max-width: $sm) {
            border-bottom: 1px solid $color-secondary-light-gray;

            display: block;

            & + tr {
              margin-top: 1rem;
            }
          }
        }

        th,
        td {
          padding: 0.5rem;

          text-align: left;
        }

        td {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          @media (max-width: $sm) {
            border-bottom: 1px solid $color-secondary-light-gray;

            display: block;

            font: 400 0.875rem Source Sans Pro, sans-serif;
            text-align: right;

            &::before {
              content: attr(data-label);

              float: left;

              font-weight: 600;
              text-transform: uppercase;
            }

            &:last-child {
              border-bottom: 0;
            }
          }
        }

        th {
          font: 700 0.875rem Source Sans Pro, sans-serif;
          text-transform: uppercase;
        }
      }
    }
  }
}
