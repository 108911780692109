@use 'colors' as *;
@use 'breakpoints' as *;

@mixin card-shadow {
  box-shadow: 0px 10px 32px -4px rgba(51, 51, 51, 0.1),
    0px 6px 14px -6px rgba(17, 17, 17, 0.12);
}

@mixin gradient($attribute) {
  #{$attribute}: $color-primary-green;
  #{$attribute}: -webkit-linear-gradient(
    to right,
    $color-primary-dark-green,
    $color-primary-green
  );
  #{$attribute}: linear-gradient(
    to right,
    $color-primary-dark-green,
    $color-primary-green
  );
}

@mixin max-width {
  max-width: 1152px;
  width: 100%;

  margin: 0 auto;

  @media (max-width: 1024px) {
    padding-right: 3rem;
    padding-left: 3rem;
  }

  @media (max-width: $md) {
    padding-right: 3rem;
    padding-left: 3rem;
  }

  @media (max-width: $sm) {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}
