@use '../../../assets/styles/utilities/colors' as *;
@use '../../../assets/styles/utilities/breakpoints' as *;
@use '../../../assets/styles/utilities/others' as *;

#selecionar-proposta {
  @include max-width;

  position: relative;
  top: -5rem;

  display: flex;
  align-items: flex-start;
  flex-direction: column;

  gap: 3rem;
}

div.selecionar-proposta-card {
  width: 100%;
  height: 100%;

  padding: 2rem;

  @include card-shadow;

  border-radius: 8px;

  background-color: $color-secondary-white;
}

.table-use{
  display: block;
}

.edital-card-products{
  .button-produto{
  display: flex;
  align-items: center;
  button{
    display: flex;
    align-items: center;
    justify-content: center;

    gap: 1rem;
    width: 20%;

    padding: 0.5rem 1rem;
    margin-top: 1rem;

    border: 0;
    border-radius: 4px;

    background-color:  $color-primary-dark-green;

    font: 600 1.125rem Source Sans Pro, sans-serif;
  }
  }
}


.edital__trigger {
  .table-column button svg {
    transition: transform 300ms;
  }

  &.is-open {
    .table-column button svg {
      transform: rotateZ(180deg);
    }
  }
}

.table-column, .table-column-produtos {
  padding: 1.5rem 0;

  button {
    font-size: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    background: transparent;

    border-radius: 0;
    border: 0;
  }
}

.button-table{
  background-color: $color-primary-green;
}

.button-table-2{
  background-color: $color-hover-danger;
}



.table-column-produtos {
  button {
    padding-left: 8rem;
  }
}