@use '../../assets/styles/utilities/colors' as *;
@use '../../assets/styles/utilities/breakpoints' as *;
@use '../../assets/styles/utilities/others' as *;

.filters {
  margin-bottom: 3rem;
}

.filterContainer {
  display: grid; 
  grid-template-columns: repeat(3, 1fr); 
  grid-template-rows: 1fr; 
  gap: 0 0.5rem; 
  width: 100%;
  height: 3rem;

  > div, 
  > input, 
  > button {
    flex: 1;
  }

  @media (max-width: $md) {
    grid-template-columns: 1fr; 
    grid-template-rows: repeat(4, 1fr); 
    gap: 0.5rem 0;
    height: auto;
  }
}

.select {
  position: relative;
  display: inline-block;
  width: 100%;
}

.select select {
  width: 100%;
  height: 3rem;

  border-radius: 4px;
  border: 1px solid $color-secondary-light-gray;

  background-color: $color-secondary-white;
  color: $color-secondary-dark-gray;
  font: 400 1rem Source Sans Pro, sans-serif;
  text-align: left;

  display: inline-block;

  padding: 0.5rem 1rem;

  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  &:hover,
  &:focus {
    background-color: $color-secondary-white;
    color: $color-secondary-dark-gray;
  }

  &::-ms-expand {
    display: none;
  }
}

.selectArrow {
  position: absolute;
  top: 1.375rem;
  right: 1rem;
  pointer-events: none;
  border-style: solid;
  border-width: 5px 5px 0px 5px;
  border-color: $color-secondary-light-gray transparent transparent
    transparent;
}

.select select:hover ~ .selectArrow,
.select select:focus ~ .selectArrow {
  border-top-color: $color-secondary-light-gray;
}
