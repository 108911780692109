@use '../../../assets/styles/utilities/colors' as *;
@use '../../../assets/styles/utilities/breakpoints' as *;
@use '../../../assets/styles/utilities/others' as *;

#contrato {
  @include max-width;

  position: relative;
  top: -5rem;

  display: flex;
  align-items: flex-start;
  flex-direction: column;

  gap: 3rem;
  padding: 0 6rem;

  @media (max-width: $md) {
    padding: 0 3rem;
  }

  @media (max-width: $sm) {
    padding: 0 1.5rem;
  }
}

.contrato-container{
  margin-top: 3rem;
  display: flex;
  border: 1px solid $color-secondary-light-gray;
  border-radius: 4px;
}


.input-custom span{
line-height: 1.5;
font-size: 1.2rem;
font-family: inherit;
}

.input-custom{
display: table;
position: relative;
padding-left: 2.5rem;
cursor: pointer;
}

.table-column, .table-column-produtos {
  padding: 1.5rem 0;
}

.table.contrato {
  
  width: 100%;
  max-width: 100%;
  

  .table-head {
    margin-top: 1rem;
    display:flex;
    height:3rem;
    width: 100%;
    margin-left:5px;
    border-top: 1px solid $color-secondary-light-gray;
    border-bottom: 1px solid $color-secondary-light-gray;

    .table-row {
      width:100%;
      display:flex;
      align-items: center;
      .table-headers {
        font: 700 0.875rem Source Sans Pro, sans-serif;
        text-transform: uppercase;
        text-align: left;


        &:last-child {
          width: 1.5rem !important;
        }
      }
    }
  }

  .table-head,
  .table-body {
    width:100%;
    .table-row {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr;

      @media (max-width: $sm) {
        grid-template-columns: 1fr 1fr 0.25fr;
      }
      .table-column {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}



.contrato-card {
  width: 100%;
  height: 100%;

  padding: 2rem;

  @include card-shadow;

  border-radius: 8px;

  background-color: $color-secondary-white;
}

div.contrato-container{
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.sm-display {
  @media (max-width: $sm) {
    display: none;
  }
}

.select{
    display: flex;
}

.contrato-card {
  .contrato-card-header {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: $sm) {
        gap: 1rem;
        flex-wrap: wrap-reverse;
      }

      h2 {
        font: 600 1.5rem Poppins, sans-serif;
        line-height: 1.5;
        color: $color-secondary-dark-gray;

        display: flex;
        align-items: center;
        gap: 1rem;
      }

      & > div {
        display: flex;
        gap: 1rem;

        @media (max-width: $sm) {
          width: 100%;

          justify-content: flex-end;

          margin-bottom: 0.5rem;
        }

        .icon {
          font-size: 0;

          background: transparent;

          border-radius: 4px;
          border: 1px solid;

          width: 2rem;
          height: 2rem;

          display: flex;
          align-items: center;
          justify-content: center;

          &.editar-edital {
            border-color: #777777;
          }

          &.remover-edital {
            border-color: #ff6b6b;
          }
        }

        button:not(.icon) {
          height: 2rem;

          display: flex;
          align-items: center;
          justify-content: center;

          border-radius: 4px;
          border: 0;

          padding: 0 0.5rem;

          font: 700 0.875rem Source Sans Pro, sans-serif;
          text-transform: uppercase;
          color: $color-secondary-white;

          &.suspender-edital {
            background-color: $color-hover-danger;
          }

          &.ativar-edital {
            background-color: $color-hover-success;
          }
        }
      }
    }

    h3 {
      font: 600 1.125rem Source Sans Pro, sans-serif;
      line-height: 1.5;
      color: $color-secondary-dark-gray;
    }

    h4 {
      font: 400 1rem Source Sans Pro, sans-serif;
      line-height: 1.5;
      color: $color-secondary-gray;
    }
  }

  .table-column-propostas {
    margin-top: 1.5rem;
    border-bottom: 1px solid $color-secondary-light-gray;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    padding-bottom: 1rem;

    
  
    

      &:last-child {
        padding-bottom: 0;
      }

      h6 {
        font: 700 0.875rem Source Sans Pro, sans-serif;
        text-transform: uppercase;
      }

      p {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }

      table {
        border: 1px solid $color-secondary-light-gray;
        border-collapse: collapse;

        margin: 0;
        margin-top: 0.5rem;
        padding: 0;

        width: 100%;

        table-layout: fixed;

        @media (max-width: $sm) {
          border: 0;

          thead {
            border: none;

            clip: rect(0 0 0 0);

            height: 1px;
            width: 1px;

            margin: -1px;
            padding: 0;

            overflow: hidden;
            position: absolute;
          }
        }

        tr {
          background-color: $color-secondary-white;

          border: 1px solid $color-secondary-light-gray;

          padding: 0.25rem;

          @media (max-width: $sm) {
            border-bottom: 1px solid $color-secondary-light-gray;

            display: block;

            & + tr {
              margin-top: 1rem;
            }
          }
        }

        th,
        td {
          padding: 0.5rem;
        }

        td {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          @media (max-width: $sm) {
            border-bottom: 1px solid $color-secondary-light-gray;

            display: block;

            font: 400 0.875rem Source Sans Pro, sans-serif;
            text-align: right;

            &::before {
              content: attr(data-label);

              float: left;

              font-weight: 600;
              text-transform: uppercase;
            }

            &:last-child {
              border-bottom: 0;
            }
          }
        }

        th {
          font: 700 0.875rem Source Sans Pro, sans-serif;
          text-transform: uppercase;
        }
      }
    
  }
}
.table-user{
  border-top: 1rem;  width: 100%;
  display: grid;
  .contrato-table-column{
    margin-top: 0.8rem;
    margin-left: 0.3rem;
    margin-bottom: 0.8rem;
  }
  border-bottom: 1px solid $color-secondary-light-gray;
}
.dados-card-body{
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  

  .component{
    margin-top: 1rem;
  }
}
h6 {
  font: 700 0.875rem Source Sans Pro, sans-serif;
  text-transform: uppercase;
}

.titulo{
  margin-top: 1.5rem;
}
.table-banco{
    display: grid;
    grid-template-columns: 1fr 1fr;
    .component{
      margin-top: 1rem;
    }
  }
div.propostas-aprovadas-card {
  @include max-width;
  display: grid;
  width: 100%;
  height: 100%;

  padding: 2rem;

  @include card-shadow;

  border-radius: 8px;

}

.input-custom input{
    position: absolute;
    top: 0;
    left: 1rem;
    height: 1.5rem;
    width: 1.2rem;
    background: $color-secondary-white;
    transition: background 250ms;
    border: 2px solid $color-primary-green;
    border-radius: 3rem;
}




.edital__trigger {
  .table-colum button svg {
    transition: transform 300ms;
  }

  &.is-open {
    .table-colum button svg {
      transform: rotateZ(180deg);
    }
  }
}


.table-colum{
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items:center;
  border-color: #b2b2b2;
  
  button {
    gap: 0.5rem;
    border: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
  }
}

.button-table{
  background-color: $color-primary-green;
}

.button-table-2{
  background-color: $color-hover-danger;
}



.table-column-produtos {
  button {
    padding-left: 8rem;
  }
}

