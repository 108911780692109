@use '../assets/styles/utilities/colors' as *;
@use '../assets/styles/utilities/breakpoints' as *;
@use '../assets/styles/utilities/others' as *;

.cadastro {
  @include max-width;

  position: relative;
  top: -5rem;

  display: flex;
  align-items: flex-start;
  flex-direction: column;

  gap: 3rem;
}

.formCard {
  width: 100%;
  height: 100%;

  padding: 2rem;

  @include card-shadow;

  border-radius: 8px;

  background-color: $color-secondary-white;

  h2 {
    font: 600 2rem Poppins, sans-serif;
    line-height: 1.5;
    color: $color-primary-green;

    margin-bottom: 0.5rem;
  }

  p {
    font: 400 1rem Source Sans Pro, sans-serif;
    color: $color-secondary-dark-gray;

    a {
      font-weight: 600;
      text-decoration: underline;
      color: $color-primary-dark-green;
    }
  }

  section {
    width: 100%;

    margin-top: 3.5rem;

    h3 {
      font: 600 1.5rem Poppins, sans-serif;
      line-height: 1.5;
      color: $color-secondary-dark-gray;

      margin-bottom: 2rem;
    }

    .radioGroup {
      width: 100%;

      display: flex;

      gap: 1rem;

      margin-bottom: 2rem;

      & + p {
        margin-bottom: 2rem;
      }
    }

    .formGroup {
      width: 100%;

      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 2rem;

      @media (max-width: $md) {
        grid-template-columns: 1fr;
      }

      .dropdown-wrapper {
        display: block;
        background-color: blue;
      }

      .add {
        align-self: flex-end;
        display: flex;
        justify-content: flex-end;

        button {
          width: 100%;
          height: 3rem;

          padding: 0.5rem 1rem;

          font: 600 1rem Source Sans Pro, sans-serif;
          color: $color-secondary-white;
          text-align: center;

          display: flex;
          align-items: center;
          justify-content: center;

          border: 0;
          border-radius: 4px;

          background-color: $color-primary-green;

          &:disabled {
            opacity: 0.8;

            cursor: not-allowed;
          }
        }
      }

      .selectGroup {
        display: flex;
        flex-direction: column;

        label {
          font: 600 1rem Source Sans Pro, sans-serif;
          color: $color-secondary-dark-gray;

          margin-bottom: 0.5rem;

          > span {
            color: $color-hover-danger;
          }
        }

        input {
          width: 100%;
          height: 3rem;

          padding: 0.5rem 1rem;

          background-color: $color-secondary-white;

          border-radius: 4px;
          border: 1px solid $color-secondary-light-gray;

          font: 400 1rem Source Sans Pro, sans-serif;
          text-align: left;
          color: $color-secondary-dark-gray;

          &::placeholder,
          &:-ms-input-placeholder,
          &:-ms-input-placeholder {
            color: $color-secondary-gray;
            opacity: 1;
          }
        }

        .select {
          position: relative;
          display: inline-block;
          width: 100%;
        }

        .select select {
          width: 100%;
          height: 3rem;

          border-radius: 4px;
          border: 1px solid $color-secondary-light-gray;

          background-color: $color-secondary-white;
          color: $color-secondary-dark-gray;
          font: 400 1rem Source Sans Pro, sans-serif;
          text-align: left;

          display: inline-block;

          padding: 0.5rem 1rem;

          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;

          &:hover,
          &:focus {
            background-color: $color-secondary-white;
            color: $color-secondary-dark-gray;
          }

          &::-ms-expand {
            display: none;
          }
        }

        .selectArrow {
          position: absolute;
          top: 1.375rem;
          right: 1rem;
          pointer-events: none;
          border-style: solid;
          border-width: 5px 5px 0px 5px;
          border-color: $color-secondary-light-gray transparent transparent
            transparent;
        }

        .select select:hover ~ .selectArrow,
        .select select:focus ~ .selectArrow {
          border-top-color: $color-secondary-light-gray;
        }
      }

      > button {
        width: 100%;
        height: 3rem;

        padding: 0.5rem 1rem;

        font: 600 1rem Source Sans Pro, sans-serif;
        color: $color-secondary-white;
        text-align: center;

        display: flex;
        align-items: center;
        justify-content: center;

        border: 0;
        border-radius: 4px;

        background-color: $color-primary-black;

        &:disabled {
          opacity: 0.8;

          cursor: not-allowed;
        }
      }

      .cancel {
        background-color: transparent;
        border: 2px solid $color-hover-danger;
        color: $color-hover-danger;
      }
    }
  }
}

.table {
  table {
    border: 1px solid $color-secondary-light-gray;
    border-collapse: collapse;

    margin: 0;
    margin-top: 0.5rem;
    padding: 0;

    width: 100%;

    table-layout: fixed;

    @media (max-width: $sm) {
      border: 0;

      thead {
        border: none;

        clip: rect(0 0 0 0);

        height: 1px;
        width: 1px;

        margin: -1px;
        padding: 0;

        overflow: hidden;
        position: absolute;
      }
    }

    tr {
      background-color: $color-secondary-white;

      border: 1px solid $color-secondary-light-gray;

      padding: 0.25rem;

      @media (max-width: $sm) {
        border-bottom: 1px solid $color-secondary-light-gray;

        display: block;

        & + tr {
          margin-top: 1rem;
        }
      }
    }

    th,
    td {
      padding: 0.5rem;

      text-align: left;
    }

    td {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @media (max-width: $sm) {
        border-bottom: 1px solid $color-secondary-light-gray;

        display: block;

        font: 400 0.875rem Source Sans Pro, sans-serif;
        text-align: right;

        &::before {
          content: attr(data-label);

          float: left;

          font-weight: 600;
          text-transform: uppercase;
        }

        &:last-child {
          border-bottom: 0;
        }
      }

      &.buttons {
        display: flex;
        justify-content: flex-end;
        gap: 0.5rem;
      }

      .icon {
        font-size: 0;

        background: transparent;

        border-radius: 4px;
        border: 0;

        width: 2rem;
        height: 2rem;

        display: flex;
        align-items: center;
        justify-content: center;

        &.edit {
          border-color: #777777;
        }

        &.remove {
          border-color: #ff6b6b;
        }
      }
    }

    th {
      font: 700 0.875rem Source Sans Pro, sans-serif;
      text-transform: uppercase;
    }
  }
}

.cpfAssociadoBlock{
  .buttonDiv{
    margin-top: 2rem;
    margin-left:1.5rem;
    display: flex;
    align-items: center;
    .deleteButton{
      background-color:  #ff0000;
      border-color: #ff0000;
      border-width: 0.1rem;
      width: 2rem;
      height: 2rem;
      color: white;
      size: 1.125rem;
      border-radius: 0.3rem;
    }
    .deleteButton:hover{
      border-color: #c00c0c;
      border-width: 0.2rem;
    }
    
  }
}

.secondaryDiv{
  order: 998;
  .secondary{
    width: 10rem;
    height: 2rem;
    background-color: #00652e;
    border-color: #00652e;
    border-radius: 0.3rem;
    color: white;
  }
  .secondary:hover{
    border-color: #39e084;
    border-width: 0.2rem;
  }
}

.plantedContainer{
  order: 999;
}
