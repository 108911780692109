@use '../assets/styles/utilities/colors' as *;
@use '../assets/styles/utilities/breakpoints' as *;
@use '../assets/styles/utilities/others' as *;

.login {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  @include gradient(background);

  padding: 0 6rem;

  @media (max-width: $md) {
    padding: 0 3rem;
  }

  @media (max-width: $sm) {
    padding: 0 1.5rem;
  }
}

.loginCard {
  width: 30rem;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;

  padding: 1.5rem;

  @include card-shadow;

  border-radius: 8px;

  background-color: $color-secondary-white;

  > img {
    max-width: 8rem;
  }

  > div {
    width: 100%;

    > h2 {
      font: 600 2rem Poppins, sans-serif;
      line-height: 1.5;
      color: $color-primary-dark-green;
      text-align: center;

      margin-bottom: 0rem;
    }

    > p {
      font: 400 1rem Source Sans Pro, sans-serif;
      color: $color-secondary-dark-gray;
      text-align: center;

      > strong {
        font-weight: 600;
        color: $color-primary-green;
      }
    }
  }

  > form {
    width: 100%;

    .formGroup {
      width: 100%;

      .inputGroup {
        width: 100%;

        display: flex;
        flex-direction: column;

        & + .inputGroup {
          margin-top: 1rem;
        }

        label {
          width: 100%;

          font: 600 1rem Source Sans Pro, sans-serif;
          color: $color-secondary-dark-gray;

          margin-bottom: 0.5rem;

          span {
            display: inline-block;

            background-color: #fce9e9;

            font: 600 1rem Source Sans Pro, sans-serif;
            color: $color-hover-danger;

            border-radius: 4px;

            padding: 0.25rem;
            margin-left: 0.5rem;
          }
        }

        input {
          width: 100%;
          height: 3rem;

          padding: 0.5rem 1rem;

          background-color: $color-secondary-white;

          border-radius: 4px;
          border: 1px solid $color-secondary-light-gray;

          font: 400 1rem Source Sans Pro, sans-serif;
          text-align: left;
          color: $color-secondary-dark-gray;

          &::placeholder,
          &:-ms-input-placeholder,
          &:-ms-input-placeholder {
            color: $color-secondary-gray;
            opacity: 1;
          }
        }
      }
    }
  }

  button {
    width: 100%;
    height: 3rem;

    padding: 0.5rem 1rem;

    font: 600 1rem Source Sans Pro, sans-serif;
    color: $color-secondary-white;
    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;

    border: 0;
    border-radius: 4px;

    background-color: $color-primary-black;

    &:disabled {
      opacity: 0.8;

      cursor: not-allowed;
    }

    &.botaoSenha {
      background-color: $color-secondary-white;

      color: $color-secondary-gray;
      font-style: italic !important;
      font-weight: 400;

      justify-content: flex-end;

      padding: 0;
      margin-bottom: 1rem;
    }

    &.botaoOutline {
      background-color: $color-secondary-white;

      border: 2px solid $color-primary-dark-green;

      color: $color-primary-dark-green;
    }
  }
}
