@use "utilities/colors" as *;
@use "utilities/breakpoints" as *;

:root,
html {
  font: 400 16px Source Sans Pro, sans-serif;
  background-color: $color-secondary-white;
}

@media (max-width: $lg) {
  :root,
  html {
    font-size: 87.5%;
  }
}

@media (max-width: $md) {
  :root,
  html {
    font-size: 75%;
  }
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*:focus {
  outline: 0 !important;
  border-color: #00652e !important;
  transition: border-color 0.2s ease-in-out;
}

html,
body,
#root {
  height: 100vh;
}

html:focus-within {
  scroll-behavior: smooth;
}

body {
  text-rendering: optimizeSpeed;
}

body,
input,
button,
textarea {
  font: 400 1rem Source Sans Pro, sans-serif;
  color: $color-secondary-dark-gray;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Poppins, sans-serif;
  font-weight: 600;
  margin: 0;
}

button {
  cursor: pointer;

  user-select: none;
}

a {
  color: inherit;
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 0;
}

::-webkit-scrollbar-track {
  background: transparent !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 0 !important;
  background: transparent !important;
}

::-webkit-scrollbar-thumb:hover {
  background: transparent !important;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

.custom-input-container *,
.custom-input-container *::before,
.custom-input-container *::after {
  box-sizing: content-box !important;
}

.custom-input-container input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-input-container span {
  line-height: 1;
  font-size: 1rem;
  font-family: inherit;
}

.custom-input-container {
  display: table;
  position: relative;
  padding-left: 1.8rem;
  cursor: pointer;
}

.custom-input-container input[type="checkbox"] ~ .custom-input {
  position: absolute;
  top: 0;
  left: 0;
  height: 1rem;
  width: 1rem;
  background: $color-secondary-white;
  transition: background 250ms;
  border: 2px solid $color-primary-green;
  border-radius: 0.125rem;
}

.custom-input-container input[type="radio"] ~ .custom-input {
  position: absolute;
  top: 0;
  left: 0;
  height: 1rem;
  width: 1rem;
  background: $color-secondary-white;
  transition: background 250ms;
  border: 2px solid $color-primary-green;
  border-radius: 3rem;
}

.custom-input-container input[type="checkbox"] ~ .custom-input::after {
  content: "";
  position: absolute;
  display: none;
  left: 0.35rem;
  top: 0.05rem;
  width: 0.25rem;
  height: 0.6rem;
  border: solid $color-secondary-white;
  border-width: 0 2px 2px 0;
  transition: background 250ms;
  transform: rotate(45deg);
}

.custom-input-container input[type="radio"] ~ .custom-input::after {
  content: "";
  position: absolute;
  display: none;
  left: 0.28rem;
  top: 0.28rem;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 3rem;
  background: $color-secondary-white;
  transition: background 250ms;
}

.custom-input-container input:disabled ~ .custom-input::after {
  border-color: $color-secondary-light-gray;
}

.custom-input-container input:checked ~ .custom-input::after {
  display: block;
}

.custom-input-container:hover input ~ .custom-input,
.custom-input-container input:focus ~ .custom-input {
  background: $color-secondary-white;
}

.custom-input-container input:focus ~ .custom-input {
  box-shadow: 0 0 0 0px $color-secondary-white;
}

.custom-input-container input:checked ~ .custom-input {
  background: $color-primary-green;
  border-color: $color-primary-green;
}

.custom-input-container input[type="checkbox"]:disabled ~ .custom-input {
  background: $color-secondary-white;
  border-color: $color-secondary-light-gray;
  opacity: 1;
  cursor: not-allowed;
}

.custom-input-container input[type="radio"]:disabled ~ .custom-input {
  background: $color-secondary-white;
  border-color: $color-secondary-light-gray;
  opacity: 1;
  cursor: not-allowed;
}

.custom-input-container input[type="radio"]:disabled ~ .custom-input::after {
  background: $color-secondary-light-gray;
}

.custom-input-container input:checked:focus ~ .custom-input,
.custom-input-container:hover input:not([disabled]):checked ~ .custom-input {
  background: $color-primary-green;
  border-color: $color-primary-green;
}

.custom-input-container .custom-input::before {
  content: "";
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 3rem;
  height: 3rem;
  margin-left: -0.85rem;
  margin-top: -0.85rem;
  background: $color-primary-green;
  border-radius: 2rem;
  opacity: 0.6;
  z-index: 99999;
  transform: scale(0);
}

.custom-input-container .custom-input::before {
  visibility: hidden;
}

.custom-input-container input:focus + .custom-input::before {
  visibility: visible;
}

.custom-input-container:first-child .custom-input::before {
  visibility: hidden;
}

.swal2-popup,
.swal2-modal {
  border-radius: 8px !important;

  background-color: $color-secondary-white;

  max-width: 25rem;

  padding: 2rem;
}

.swal2-title {
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  color: $color-primary-dark-green;

  padding: 0;
  margin-bottom: 0.5rem;
}

.swal2-html-container {
  font: 400 1rem Source Sans Pro, sans-serif;
  color: $color-secondary-dark-gray;

  padding: 0;
  margin-bottom: 1rem;
}

.swal2-actions {
  margin-top: 0;
}

.swal2-confirm,
.swal2-deny,
.swal2-cancel {
  width: 100%;
  height: 3rem;

  border: 0;
  border-radius: 4rem;

  padding: 0.5rem 1rem;
  margin: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 0.2s ease-in-out;
  background: $color-primary-green !important;

  color: $color-secondary-white !important;

  & + button {
    margin-top: 1rem;
  }

  &:hover {
    background-color: darken($color-primary-green, 5%) !important;
    transition: all 0.2s ease-in-out;
  }

  &.swal-confirm-button {
    background: $color-hover-danger !important;
  }

  &.swal-cancel-button {
    background: $color-secondary-white !important;

    border: 1px solid $color-secondary-light-gray;

    color: $color-secondary-gray !important;
  }
}

.swal2-input {
  width: 100%;
  height: 3rem;

  padding: 0.5rem 1rem;
  margin: 0 0 1rem;

  background-color: $color-secondary-white;

  border-radius: 4px;
  border: 1px solid $color-secondary-light-gray;
  box-shadow: none !important;

  font: 400 1rem Source Sans Pro, sans-serif;
  text-align: left;
  color: $color-secondary-dark-gray;

  &::placeholder,
  &:-ms-input-placeholder,
  &:-ms-input-placeholder {
    color: $color-secondary-gray !important;
    opacity: 1;
  }

  &:focus {
    border: 1px solid $color-secondary-light-gray;
  }
}

.Toastify__toast {
  background-color: $color-secondary-white !important;

  border-radius: 4px !important;

  font: 400 1rem Source Sans Pro, sans-serif;
  color: $color-primary-dark-green !important;
}

.tippy-popper[x-placement^="bottom"] [x-arrow] {
  border-bottom-color: $color-primary-green !important;
}

.tippy-popper[x-placement^="top"] [x-arrow] {
  border-top-color: $color-primary-green !important;
}

.tippy-popper[x-placement^="right"] [x-arrow] {
  border-right-color: $color-primary-green !important;
}

.tippy-tooltip-content {
  color: $color-secondary-white !important;
  font-weight: 400;
}

.tippy-tooltip {
  background-color: $color-primary-green !important;
}

.tippy-tooltip-content {
  text-align: justify;
}
