@use '../../assets/styles/utilities/colors' as *;
@use '../../assets/styles/utilities/breakpoints' as *;
@use '../../assets/styles/utilities/others' as *;

.inputGroup {
  display: flex;
  flex-direction: column;

  > label {
    font: 600 1rem Source Sans Pro, sans-serif;
    color: $color-secondary-dark-gray;

    display: flex;
    align-items: center;

    &.containLabel {
      margin-bottom: 0.5rem;
    }

    > span {
      color: $color-hover-danger;
    }

    div > button {
      width: 1.5rem;
      height: 1rem;

      margin-left: 0.25rem;

      background: transparent;

      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 0;

      border: 0;
    }
  }

  input {
    width: 100%;
    height: 3rem;

    padding: 0.5rem 1rem;

    background-color: $color-secondary-white;

    border-radius: 4px;
    border: 1px solid $color-secondary-light-gray;

    font: 400 1rem Source Sans Pro, sans-serif;
    text-align: left;
    color: $color-secondary-dark-gray;

    &::placeholder,
    &:-ms-input-placeholder,
    &:-ms-input-placeholder {
      color: $color-secondary-gray;
      opacity: 1;
    }
  }

  input:disabled {
    background-color: #e8e8e8;
    color: #b5b5b5;
  }
}
