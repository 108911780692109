.planted-label {
    font: 600 1rem Source Sans Pro, sans-serif;
    color: #333333;
    .required-asterisk {
        margin-left:1px;
        color: #ee5253;
    }
}

.planted-checkbox {
    margin-left: 5px;
    padding: 5px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
    option{
        font-size: 16px;
    }
    :focus{
        outline: none;
        border-color: #007944;
        box-shadow: 0 0 3px rgba(0, 123, 70, 0.7);
    }
}


