@use '../../assets/styles/utilities/colors' as *;
@use '../../assets/styles/utilities/breakpoints' as *;
@use '../../assets/styles/utilities/others' as *;

.filterButton {
    width: 100%;
    height: 3rem;
  
    padding: 0.5rem 1rem;
  
    font: 600 1rem Source Sans Pro, sans-serif;
    color: $color-secondary-white;
    text-align: center;
  
    display: flex;
    align-items: center;
    justify-content: center;
  
    border: 0;
    border-radius: 4px;
  
    background-color: $color-primary-green;
  
    &:disabled {
      opacity: 0.8;
  
      cursor: not-allowed;
    }
  }