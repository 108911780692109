@use '../../assets/styles/utilities/colors' as *;
@use '../../assets/styles/utilities/breakpoints' as *;
@use '../../assets/styles/utilities/others' as *;

.nav {
  @include max-width;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 1.5rem 0 !important;
  margin-bottom: 2rem;

  h3 {
    color: $color-secondary-white;
    font: 600 1.5rem Poppins, sans-serif;
  }

  .headerButton{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    gap: 1rem;

    padding: 0.5rem 1rem;

    border: 0;
    border-radius: 4px;

    background-color: $color-secondary-white;

    font: 600 1.125rem Source Sans Pro, sans-serif;
    color: $color-primary-dark-green;
  }
}
