@use "../../assets/styles/utilities/colors" as *;
@use "../../assets/styles/utilities/breakpoints" as *;
@use "../../assets/styles/utilities/others" as *;

#error {
  width: 100%;
  height: 3rem;

  padding: 0.5rem 1rem;

  background-color: #fce9e9;

  font: 600 1rem Source Sans Pro, sans-serif;
  color: $color-hover-danger;

  border-radius: 4px;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  gap: 1rem;

  margin-top: 1rem;
}
